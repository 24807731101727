// Those animations are here for reference purpose

import {animate, keyframes, query, state, style, transition, trigger} from '@angular/animations';

export const flyInOut = trigger('flyInOut', [
  state('in', style({transform: 'translateX(0)'})),
  transition('* => *', [
    animate(400, keyframes([
      style({opacity: 0, transform: 'translateX(100%)', offset: 0}),
      style({opacity: 1, transform: 'translateX(-12px)',  offset: 0.3}),
      style({opacity: 1, transform: 'translateX(0)',     offset: 1.0})
    ]))
  ]),
  transition('* => void', [
    animate(400, keyframes([
      style({opacity: 1, transform: 'translateX(0)',     offset: 0}),
      style({opacity: 1, transform: 'translateX(12px)', offset: 0.7}),
      style({opacity: 0, transform: 'translateX(-100%)',  offset: 1.0})
    ]))
  ])
]);


export const visibilityChanged = trigger('visibilityChanged', [
  state('out', style({
    opacity: 1
  })),
  state('in',   style({
    opacity: 0
  })),
  transition('show => hide', animate('600ms ease-out')),
  transition('hide => show', animate('1000ms ease-in'))
]);

export const fadeAnimation1 = trigger('fadeAnimation1', [
  transition('* => *', [
    query(
      ':enter',
      [style({ opacity: 0 })],
      { optional: true }
    ),
    query(
      ':leave',
      [style({ opacity: 1 }), animate('0.300ms ease-out', style({ opacity: 0 }))],
      { optional: true }
    ),
    query(
      ':enter',
      [style({ opacity: 0 }), animate('0.300ms ease-in', style({ opacity: 1 }))],
      { optional: true }
    )
  ])
]);
